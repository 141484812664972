// parse extension with filename
export const parseExtension = (filename) => {
  let extensionsList = filename.split(".");
  let extLen = extensionsList.length;
  return extensionsList[extLen - 1];
};

export const uniqueString = (length = 16) => {
  let randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

export const displayOrgName = (orgInfo) => {
  if (!orgInfo.companyBuildingName) {
    return orgInfo.companyName;
  } else {
    return `${orgInfo.companyName}`; //  - ${orgInfo.companyBuildingName}
  }
};

export const extractNameto3 = (fullname) => {
  try {
    fullname = fullname.trim();
    if (fullname) {
      let extractedInitials = fullname.split(" ");
      extractedInitials = extractedInitials.filter((n) => n);
      let returnName = {
        firstName: extractedInitials[0],
        lastName: extractedInitials[extractedInitials.length - 1],
        middleName: "",
      };
      extractedInitials.shift();
      extractedInitials.pop();
      if (extractedInitials.length) {
        returnName.middleName = extractedInitials.join(" ");
      }

      return returnName;
    }
    return {
      firstName: "",
      lastName: "",
      middleName: "",
    };
  } catch (err) {
    return {
      firstName: "",
      lastName: "",
      middleName: "",
    };
  }
};
