import {
  FRONTMEMBER_ERROR,
  FRONTMEMBER_UPDATED,
  FRONTMEMBER_GET_BY_ID,
  FRONTMEMBER_LOADING_ON_SUBMIT,
  FRONTMEMBER_RESET,
  FRONTMEMBER_LOAD_PAGE,
  FRONTMEMBER_EMPLOYER_GET_BY_ID,
  FRONTMEMBER_INSURANCE_ON_SUBMIT,
  FRONTMEMBER_INSURANCE_UPDATED,
  FRONTMEMBER_CONSENT_FORM_SAVED,
  FRONTMEMBER_IMMEGRATION__FORM_KEYS_UPDATED,
  FRONTMEMBER_IMMIGRATION_UPDATED,
  FRONTMEMBER_IMMIGRATION_DATA_UPDATED,
} from "actions/types";

const initialState = {
  currentMember: null,
  // authId: false,
  loadingMember: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  loadingInsuranceForm: false,
  immigrationFormKeys: [],
  currentImmigration: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTMEMBER_RESET:
      return {
        ...initialState,
        loadingMember: state.loadingMember,
      };
    case FRONTMEMBER_LOAD_PAGE:
      return {
        ...state,
        loadingMember: false,
      };
    case FRONTMEMBER_UPDATED:
      return {
        ...state,
        currentMember: payload,
        sortingParams: initialState.sortingParams,
        loadingMember: false,
      };
    case FRONTMEMBER_ERROR:
      return {
        ...state,
        error: payload,
        loadingMember: false,
        loadingMemberList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTMEMBER_EMPLOYER_GET_BY_ID:
      return {
        ...state,
        currentEmployer: payload,
        loadingMember: false,
      };
    case FRONTMEMBER_GET_BY_ID:
      return {
        ...state,
        currentMember: payload,
        loadingMember: false,
      };
    case FRONTMEMBER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingMember: true,
      };
    case FRONTMEMBER_INSURANCE_ON_SUBMIT:
      return {
        ...state,
        loadingInsuranceForm: true,
      };
    case FRONTMEMBER_INSURANCE_UPDATED:
      return {
        ...state,
        loadingInsuranceForm: false,
        currentMember: {
          ...state.currentMember,
          paymentBy: "2",
          insurance: payload,
        },
      };

    case FRONTMEMBER_CONSENT_FORM_SAVED:
      return {
        ...state,
        currentMember: {
          ...state.currentMember,
          user: {
            ...state.currentMember.user,
            consentForm: payload.user.consentForm,
          },
        },
      };

    case FRONTMEMBER_IMMEGRATION__FORM_KEYS_UPDATED:
      return {
        ...state,
        immigrationFormKeys: payload,
      };

    case FRONTMEMBER_IMMIGRATION_UPDATED: {
      return {
        ...state,
        currentMember: {
          ...state.currentMember,
          immigration: true,
        },
      };
    }
    case FRONTMEMBER_IMMIGRATION_DATA_UPDATED: {
      return {
        ...state,
        currentImmigration: payload,
      };
    }
    default:
      return state;
  }
}
